import styled from "styled-components";

export const Background = styled.div`
  min-height: calc(100vh - 80px);
  padding: 0.5rem calc((100vw - 1000px) / 2);
  background-color: #0093e9;
  background-image: linear-gradient(
    0deg,
    #0093e9 0%,
    #99d4f7 50%,
    #ffffff 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div``;

export const Text = styled.div`
  bottom: 10%;
  position: absolute;
  color: white;
  font-size: 3rem;
  justify-content: center;
`;

export const Image = styled.div`
  height: 80vh;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transition: transform 1s;
  transform: ${(props) => (props.hidden ? "scale(0.005)" : "scale(1)")};
  display: flex;
  justify-content: center;

  img {
    height: 100%;
  }
`;

export const Title = styled.h1`
  width: 100%;
`;
