import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {
  isAuthenticatedAdmin,
  isAuthenticatedCoordenador,
  isAuthenticatedRh,
  isAuthenticatedSecretaria,
  isAuthenticatedDev,
  isAuthenticatedDiretor,
} from "../src/auth";
import Navbar from "./components/Navbar";
import FloatingMenu from "./components/FloatingMenu";

// Rotas públicas
import Index from "./pages/Index";

// Rotas privadas de coordenador
import Coord from "./pages/Coordenador/Home";
import LancamentoTurmas from "./pages/Coordenador/LancamentoTurmas/index";
import LancamentoTurma from "./pages/Coordenador/RegisterLancamentoTurmas/";
import TurmasGrade from "./pages/Coordenador/LancamentoGradeTurmas/index";
import TurmasCoord from "./pages/Coordenador/RegisterLancamentoGradeTurmas";
import Eventuais from "./pages/Coordenador/Eventuais/index";
import RegisterEventuais from "./pages/Coordenador/RegisterEventuais/";
import FecharCoord from "./pages/Coordenador/Fechamento";
import LancarCoord from "./pages/Coordenador/RegisterCursos";
import PdfGradeTurma from "./pages/Coordenador/PdfGradeTurma";
import PdfFechamentoCoord from "./pages/Coordenador/PdfFechamentoProfessores";
// Tela que foi substituída pela outra TrocaProfessor, e não sabemos para que serve essa
// import TrocaProfessor from "./pages/Coordenador/TrocaProfessor";
import TrocaProfessorDetalhe from "./pages/Coordenador/TrocaProfessorDetalhe";
import RegisterGrades from "./pages/Coordenador/RegisterGrades";
import ListTurmas from "./pages/Coordenador/Turmas";
import ListGrades from "./pages/Coordenador/Grades";
import GradesCurriculares from "./pages/Coordenador/GradesCurriculares";
import RegisterGradesCurriculares from "./pages/Coordenador/RegisterGradesCurriculares/index";
import EditGradesCurriculares from "./pages/Coordenador/EditGradesCurriculares/index";
import RegisterCursos from "./pages/Coordenador/RegisterCursos";
import EdicaoHorarios from "./pages/Coordenador/EdicaoHorarios";
import ConsultaCoord from "./pages/Coordenador/Consulta/";
import PdfProfessoresTrocados from "./pages/Coordenador/PdfProfessoresTrocados/index";

// Rotas privadas de rh
import Rh from "./pages/Rh/Home";
import RegrasRh from "./pages/Rh/Regras";
import FecharRhProfessores from "./pages/Rh/Fechamento/Professores";
import FecharRhCoordenadores from "./pages/Rh/Fechamento/Coordenadores";
import Usuarios from "./pages/Rh/Usuarios/index";
import DefinicaoCoord from "./pages/Rh/DefineCoord/";
import Deslocamentos from "./pages/Rh/Deslocamentos/";
import RegisterCusto from "./pages/Rh/CentrosCusto";
import Colaboradores from "./pages/Rh/Colaboradores/";
import Lancamentos from "./pages/Rh/Lancamentos/";
import Consulta from "./pages/Rh/Consulta";
import Eventos from "./pages/Rh/Eventos";
import FechamentoPDF from "./pages/Rh/PdfFechamento";
import RhTurmas from "./pages/Rh/Turmas";

// Rotas privadas de secretaria
import Sec from "./pages/Secretaria/Home";
import Cursos from "./pages/Secretaria/Cursos";
import Disciplinas from "./pages/Coordenador/Disciplinas/index.js";
import Turnos from "./pages/Secretaria/Turno/index.js";
import Unidades from "./pages/Secretaria/Unidades/";
import ExportarSec from "./pages/Secretaria/Exportar";

// Rotas privadas do dev
import Logs from "./pages/Dev/Logs";
import HomeDev from "./pages/Dev/Home";
import RegrasDev from "./pages/Dev/Regras";

// PageNotFound
import PageNotFound from "./pages/PageNotFound";

export default function Routes() {
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );

  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       isAuthenticatedCoordenador() ||
  //       isAuthenticatedAdmin() ||
  //       isAuthenticatedRh() ||
  //       isAuthenticatedDev() ||
  //       isAuthenticatedSecretaria() ? (
  //         <Component {...props} />
  //       ) : (
  //         <Redirect to={{ pathname: "/", state: { from: props.location } }} />
  //       )
  //     }
  //   />
  // );

  // const AdminRoute = ({ component: Component, ...rest }) => (
  //     <Route {...rest} render={props => (
  //         isAuthenticatedAdmin() ? (
  //             <Component {...props} />
  //         ) : (
  //             <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  //         )
  //     )} />
  // )

  const DevRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedDev() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  const CoordRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedCoordenador() ||
        isAuthenticatedAdmin() ||
        isAuthenticatedDev() ||
        isAuthenticatedSecretaria() ||
        isAuthenticatedRh() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  const RhRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedRh() ||
        isAuthenticatedAdmin() ||
        isAuthenticatedDev() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  const SecRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedSecretaria() ||
        isAuthenticatedAdmin() ||
        isAuthenticatedDev() ||
        isAuthenticatedRh() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  const DirRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticatedDiretor() ||
        isAuthenticatedCoordenador() ||
        isAuthenticatedAdmin() ||
        isAuthenticatedDev() ||
        isAuthenticatedRh() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <PublicRoute exact path="/" component={Index} />

        <CoordRoute exact path="/coord" component={Coord} />
        <CoordRoute exact path="/gradeturmas" component={TurmasGrade} />
        <CoordRoute
          exact
          path="/lancamentoturmas"
          component={LancamentoTurmas}
        />
        <CoordRoute exact path="/edicaohorarios" component={EdicaoHorarios} />
        <CoordRoute exact path="/pdfgradeturma" component={PdfGradeTurma} />
        <CoordRoute exact path="/lancamentoturma" component={LancamentoTurma} />
        <CoordRoute exact path="/turmascoord" component={TurmasCoord} />
        <CoordRoute exact path="/lanctoturmas" component={RegisterCursos} />
        <CoordRoute
          exact
          path="/trocaprofessordetalhe"
          component={TrocaProfessorDetalhe}
        />
        <CoordRoute exact path="/eventuais" component={Eventuais} />
        <CoordRoute
          exact
          path="/eventuaiscoord"
          component={RegisterEventuais}
        />
        <CoordRoute exact path="/fechamentocoord" component={FecharCoord} />
        <CoordRoute exact path="/lancamentocursos" component={LancarCoord} />
        <CoordRoute exact path="/registergrades" component={RegisterGrades} />
        <CoordRoute exact path="/turmas" component={ListTurmas} />
        <CoordRoute exact path="/grades" component={ListGrades} />
        <CoordRoute
          exact
          path="/gradescurriculares"
          component={GradesCurriculares}
        />
        <CoordRoute
          exact
          path="/registergradescurriculares"
          component={RegisterGradesCurriculares}
        />
        <CoordRoute
          exact
          path="/editgradescurriculares"
          component={EditGradesCurriculares}
        />
        <CoordRoute exact path="/disciplinas" component={Disciplinas} />
        <CoordRoute exact path="/consultacoord" component={ConsultaCoord} />
        <CoordRoute
          exact
          path="/fechamentopdfcoord"
          component={PdfFechamentoCoord}
        />
        <CoordRoute
          exact
          path="/professorestrocadospdf"
          component={PdfProfessoresTrocados}
        />

        <RhRoute exact path="/aulaspordia" component={Rh} />
        <RhRoute exact path="/rh" component={FecharRhCoordenadores} />
        <RhRoute exact path="/regrasrh" component={RegrasRh} />
        <RhRoute
          exact
          path="/fechamentorhprof"
          component={FecharRhProfessores}
        />
        <RhRoute exact path="/usuarios" component={Usuarios} />
        <RhRoute exact path="/definicaocoord" component={DefinicaoCoord} />
        <RhRoute exact path="/colaboradores" component={Colaboradores} />
        <RhRoute exact path="/registercusto" component={RegisterCusto} />
        <RhRoute exact path="/deslocamentos" component={Deslocamentos} />
        <RhRoute exact path="/lancamentos" component={Lancamentos} />
        <RhRoute exact path="/consulta" component={Consulta} />
        <RhRoute exact path="/eventos" component={Eventos} />
        <RhRoute exact path="/fechamentopdfRH" component={FechamentoPDF} />
        <RhRoute exact path="/rhturmas" component={RhTurmas} />

        <SecRoute exact path="/sec" component={Sec} />
        <SecRoute exact path="/turnos" component={Turnos} />
        <SecRoute exact path="/unidades" component={Unidades} />
        <SecRoute exact path="/exportarsec" component={ExportarSec} />
        <SecRoute exact path="/cursos" component={Cursos} />
        <SecRoute exact path="/consultasec" component={Consulta} />

        <DirRoute exact path="/dir" component={RegisterEventuais} />

        <DevRoute exact path="/logs" component={Logs} />
        <DevRoute exact path="/homedev" component={HomeDev} />
        <DevRoute exact path="/regrasdev" component={RegrasDev} />

        <Route path="*" component={PageNotFound} />
      </Switch>
      <FloatingMenu />
    </BrowserRouter>
  );
}
