import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
  Select,
  Label,
  InputLabel,
  CheckBox,
  InputLabelCheckbox,
} from "./style";
import api from "../../../../services/api";
import Swal from "sweetalert2";
import Auth from "../../../../services/auth";
import { IoMdCheckmark } from "react-icons/io";

export default function ModalEdit({ showModal, setShowModal, data }) {
  const modalRef = useRef();
  const [idCoord] = useState(Auth.id());
  const [unidades, setUnidades] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState("");
  const [centros_custo, setCentros_custo] = useState([]);
  const [centro_custo, setCentro_custo] = useState("");
  const [carga_horaria, setCarga_horaria] = useState("");
  const [professores, setProfessores] = useState([]);
  const [professor, setProfessor] = useState("");
  const [id_professor, setId_professor] = useState(0);
  const [justificativa, setJustificativa] = useState("");
  const [data_inicio, setData_inicio] = useState("");
  const [data_fim, setData_fim] = useState("");
  const [id, setId] = useState("");
  const [idUnidade, setIdUnidade] = useState(0);
  const [id_centro_custo, setId_centro_custo] = useState(0);
  const [idCurso, setIdCurso] = useState(0);
  const [tipos_lancamentos, setTipos_lancamentos] = useState([]);
  const [tipo_lancamento, setTipo_lancamento] = useState("");
  const [id_tipo_lancamento, setId_tipo_lancamento] = useState(0);
  const [valorIda, setValorIda] = useState(0);
  const [valorVolta, setValorVolta] = useState(0);
  const [origemDestino, setOrigemDestino] = useState(0);
  const [quantidadeDeslocamentos, setQuantidadeDeslocamentos] = useState(0);
  const [deslocamentos, setDeslocamentos] = useState([]);
  const [valorIdaChecked, setValorIdaChecked] = useState(false);
  const [valorVoltaChecked, setValorVoltaChecked] = useState(false);
  const history = useHistory();
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    api
      .get(`/coord/units?idCoord=${idCoord}`)
      .then((response) => {
        setUnidades(response.data.unidades);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
    api
      .get(`/coord/contributors`)
      .then((response) => {
        setProfessores(response.data.colaboradores);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, idCoord]);

  useEffect(() => {
    api
      .get(`/coord/costcenter?idCoord=${idCoord}&idUnidade=${idUnidade}`)
      .then((response) => {
        setCentros_custo(response.data.centros_custo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, idCoord, idUnidade]);

  useEffect(() => {
    api
      .get(
        `/coord/tipos?id_centro_custo=${id_centro_custo}&matricula_colaborador=${id_professor}`
      )
      .then((response) => {
        setTipos_lancamentos(response.data.tipos_lancamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, id_centro_custo, id_professor]);

  useEffect(() => {
    api
      .get(
        `/coord/courses?idCoord=${idCoord}&idUnidade=${idUnidade}&idCentroCusto=${id_centro_custo}`
      )
      .then((response) => {
        setCursos(response.data.cursos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [history, idCoord, idUnidade, id_centro_custo]);

  useEffect(() => {
    api
      .get(
        `/coord/deslocamentos${
          id_centro_custo ? `?id_centro_custo=${id_centro_custo}` : ""
        }`
      )
      .then((response) => {
        setDeslocamentos(response.data.deslocamentos);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar os dados!",
          icon: "error",
        });
      });
  }, [centro_custo, history, id_centro_custo]);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  useEffect(() => {
    if (data) {
      setId(data.id);
      setIdUnidade(data.id_unidade);
      setUnidade(data.unidade);
      setCurso(data.curso);
      setIdCurso(data.id_curso);
      setCentro_custo(data.centro_custo);
      setId_centro_custo(data.id_centro_custo);
      setId(data.id);
      setCarga_horaria(data.carga_horaria);
      setTipo_lancamento(data.tipo_lancamento);
      setId_tipo_lancamento(data.id_tipo_lancamento);
      setProfessor(data.professor);
      setId_professor(data.id_professor);
      setJustificativa(data.justificativa);
      setValorIda(data.valor_ida);
      setValorVolta(data.valor_volta);
      setValorIdaChecked(data.valor_ida ? true : false);
      setValorVoltaChecked(data.valor_volta ? true : false);
      setQuantidadeDeslocamentos(data.quantidade_deslocamentos);
      setOrigemDestino(
        deslocamentos.filter(
          (d) =>
            d.id_cidade_origem === data.id_cidade_origem &&
            d.id_cidade_destino === data.id_cidade_destino
        )[0]
          ? deslocamentos.filter(
              (d) =>
                d.id_cidade_origem === data.id_cidade_origem &&
                d.id_cidade_destino === data.id_cidade_destino
            )[0].id
          : 0
      );
      if (data.data_inicio) {
        setData_inicio(
          data.data_inicio.substring(6, 10) +
            "-" +
            data.data_inicio.substring(3, 5) +
            "-" +
            data.data_inicio.substring(0, 2)
        );
      }
      if (data.data_fim) {
        setData_fim(
          data.data_fim.substring(6, 10) +
            "-" +
            data.data_fim.substring(3, 5) +
            "-" +
            data.data_fim.substring(0, 2)
        );
      }
    }
  }, [data, deslocamentos]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      id: id,
      id_unidade: idUnidade,
      id_tipo_lancamento: id_tipo_lancamento,
      id_colaborador: id_professor,
      id_curso: idCurso,
      id_centro_custo: id_centro_custo,
      data_inicio: data_inicio,
      data_fim: data_fim,
      carga_horaria: carga_horaria,
      justificativa: justificativa,
      id_cidade_origem: deslocamentos.filter((d) => d.id === origemDestino)[0]
        ? deslocamentos.filter((d) => d.id === origemDestino)[0]
            .id_cidade_origem
        : undefined,
      id_cidade_destino: deslocamentos.filter((d) => d.id === origemDestino)[0]
        ? deslocamentos.filter((d) => d.id === origemDestino)[0]
            .id_cidade_destino
        : undefined,
      valor_ida: valorIda,
      valor_volta: valorVolta,
      id_deslocamento: id,
      qtde: quantidadeDeslocamentos,
    };
    try {
      const response = await api.patch(`/coord/events`, { data });
      if (response.data.error) {
        return Swal.fire("Atenção!", response.data.error, "warning");
      }
      Toast.fire({
        icon: "success",
        title: "Editado com sucesso!",
      });
      setShowModal(!showModal);
    } catch (error) {
      alert(error);
    }
  }
  return (
    <Background
      show={showModal}
      ref={modalRef}
      onClick={(e) => {
        if (modalRef.current === e.target) {
          setShowModal(!showModal);
        }
      }}
    >
      <animated.div style={animation}>
        <ModalContent>
          <br />
          <Form onSubmit={handleSubmit}>
            <h3>Atualizar Lançamento Eventual</h3>
            <FormContent>
              <InputLabel>
                <Label>Unidade:</Label>
                <Select
                  value={unidade}
                  placeholder="unidade"
                  onChange={(e) => {
                    setUnidade(e.target.value);
                    setIdUnidade(e.target[e.target.options.selectedIndex].id);
                  }}
                  required
                >
                  {unidades.map((u, i) => (
                    <option id={u.id} key={i}>
                      {u.nome}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Tipo Lançamento:</Label>
                <Select
                  value={tipo_lancamento}
                  onChange={(e) => {
                    setTipo_lancamento(e.target.value);
                    setId_tipo_lancamento(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                  required
                >
                  {tipos_lancamentos.map((t, i) => (
                    <option id={t.id} key={i}>
                      {t.nome}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Professores:</Label>
                <Select
                  value={professor}
                  onChange={(e) => {
                    setProfessor(e.target.value);
                    setId_professor(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                  required
                >
                  {professores.map((p, i) => (
                    <option id={p.matricula} key={i}>
                      {p.nome}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento
                    ? tipo_lancamento.toLowerCase().includes("deslocamento")
                      ? ""
                      : "none"
                    : "none",
                }}
              >
                <Label>Origem / Destino:</Label>
                <Select
                  value={origemDestino}
                  onChange={(e) => {
                    setOrigemDestino(parseInt(e.target.value));
                  }}
                >
                  {deslocamentos.map((d, i) => (
                    <option key={i} value={d.id}>
                      {d.cidade_origem + " / " + d.cidade_destino}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Curso:</Label>
                <Select
                  value={curso}
                  placeholder="curso"
                  onChange={(e) => {
                    setCurso(e.target.value);
                  }}
                  required
                >
                  {cursos.map((c, i) => (
                    <option key={i} id={c.id}>
                      {c.curso}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Centro de Custo:</Label>
                <Select
                  value={centro_custo}
                  placeholder="centro_custo"
                  onChange={(e) => {
                    setCentro_custo(e.target.value);
                    setId_centro_custo(
                      e.target[e.target.options.selectedIndex].id
                    );
                  }}
                  required
                >
                  {centros_custo.map((c, i) => (
                    <option id={c.id} key={i}>
                      {c.nome}
                    </option>
                  ))}
                </Select>
              </InputLabel>
              <InputLabel>
                <Label>Data Início:</Label>
                <Input
                  type="date"
                  value={data_inicio}
                  onChange={(e) => setData_inicio(e.target.value)}
                  required
                />
              </InputLabel>
              <InputLabel>
                <Label>Data Fim:</Label>
                <Input
                  type="date"
                  value={data_fim}
                  onChange={(e) => setData_fim(e.target.value)}
                  required
                />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento
                    ? tipo_lancamento.toLowerCase().includes("deslocamento")
                      ? "none"
                      : ""
                    : "",
                }}
              >
                <Label>Carga Horária:</Label>
                <Input
                  type="text"
                  value={carga_horaria}
                  onChange={(e) => setCarga_horaria(e.target.value)}
                  required
                />
              </InputLabel>
              <InputLabelCheckbox
                style={{
                  display: tipo_lancamento
                    ? tipo_lancamento.toLowerCase().includes("deslocamento")
                      ? ""
                      : "none"
                    : "none",
                }}
              >
                <CheckBox
                  onClick={() => {
                    setValorIdaChecked(!valorIdaChecked);
                  }}
                >
                  <IoMdCheckmark
                    style={{
                      transition: "all 0.2s ease-in-out",
                    }}
                    color={
                      valorIdaChecked ? "var(--blue-color)" : "transparent"
                    }
                  />
                </CheckBox>
                <Label>Valor Ida:</Label>
                <Input
                  type="number"
                  value={valorIda}
                  onChange={(e) => setValorIda(e.target.value)}
                  required
                  disabled={!valorIdaChecked}
                />
              </InputLabelCheckbox>
              <InputLabelCheckbox
                style={{
                  display: tipo_lancamento
                    ? tipo_lancamento.toLowerCase().includes("deslocamento")
                      ? ""
                      : "none"
                    : "none",
                }}
              >
                <CheckBox
                  onClick={() => {
                    setValorVoltaChecked(!valorVoltaChecked);
                  }}
                >
                  <IoMdCheckmark
                    style={{
                      transition: "all 0.2s ease-in-out",
                    }}
                    color={
                      valorVoltaChecked ? "var(--blue-color)" : "transparent"
                    }
                  />
                </CheckBox>
                <Label>Valor Volta:</Label>
                <Input
                  type="number"
                  value={valorVolta}
                  onChange={(e) => setValorVolta(e.target.value)}
                  required
                  disabled={!valorVoltaChecked}
                />
              </InputLabelCheckbox>
              <InputLabel
                style={{
                  display: tipo_lancamento
                    ? tipo_lancamento.toLowerCase().includes("deslocamento")
                      ? ""
                      : "none"
                    : "none",
                }}
              >
                <Label>Quantidade:</Label>
                <Input
                  type="number"
                  value={quantidadeDeslocamentos}
                  onChange={(e) => setQuantidadeDeslocamentos(e.target.value)}
                  required
                />
              </InputLabel>
              <InputLabel
                style={{
                  display: tipo_lancamento
                    ? tipo_lancamento.toLowerCase().includes("deslocamento")
                      ? "none"
                      : ""
                    : "",
                }}
              >
                <Label>Justificativa:</Label>
                <Input
                  type="text"
                  value={justificativa}
                  onChange={(e) => setJustificativa(e.target.value)}
                />
              </InputLabel>
            </FormContent>
            <br />
            <Button
              type="button"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Atualizar
            </Button>
          </Form>
          <CloseModalButton
            onClick={() => {
              setShowModal(!showModal);
            }}
          />
        </ModalContent>
      </animated.div>
    </Background>
  );
}
